.input-group-text.is-invalid {
  border: 1px solid #f1556c;
  color: #f1556c;
}

li.menuitem-active {
  background: #f3eff4;
}

.Toastify__toast-container {
  .Toastify__toast {
    color: #fff;

    svg {
      fill: #fff;
    }
  }

  .Toastify__toast--error {
    background: #e74c3ced;
  }

  .Toastify__toast--success {
    background: #07bc0ceb;
  }
}

.action-coulmn {
  .btn {
    font-size: 18px;
  }
}

.k-filtercell-wrapper {
  max-width: 300px;
}

.k-filtercell-operator {
  display: none;
}

.list-thumbnail {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.modal {
  label {
    margin-bottom: 0.5rem;
  }
}

.form-control-select {
  &.is-invalid {
    .select__control {
      border-color: #f1556c;
    }
  }
}

#display-pic-input {
  display: none;
}

.dp-edit-icon {
  position: absolute;
  bottom: 5px;
  left: 55%;
  color: #fff;
  background: #096da9;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

.auth-btn {
  padding: .70rem .9rem;
}

.badge-primary {
  color: var(--bs-white);
  background-color: var(--bs-gray-700);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
  margin: 3px;
  font-size: 12px;
  font-weight: 400;
}

.rating-icon {
  color: #FABD07;
  font-size: 22px;
}

.settings-container {
  .tab-content {
    padding-top: 0;
  }

  .update-msgs-textbox {
    height: 80px;
  }
}

@media (max-width: 480px) {
  .k-pager-info {
    margin: 0;
  }
}